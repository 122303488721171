import { losseContentParse, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import useInfo from '~/utils/useInfo'
import Logo from '../elements/Logo'
import { Fragment } from 'react'
import Socials from '../elements/Socials'
import clsx from 'clsx'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer, page } = useLoaderData<FooterLoaderData>()
  const info = useInfo()
  const lastFlexElement = page.flexContent?.flex[page.flexContent?.flex.length - 1]

  return (
    <footer data-component="Footer">
      <div className={clsx('section--detail--top', lastFlexElement?.styletype === 'contact' ? 'bg-ftf-mine-shaft' : 'bg-white')} />
      <div className="bg-gradient-to-b from-ftf-mine-shaft from-20% to-ftf-mine-shafter">
        <div className="container max-w-screen-2xl pt-20 pb-10">
          <div className="grid grid-col-1 xs:grid-cols-4 lg:grid-cols-8 xl:grid-cols-11 gap-x-10 gap-y-10 xs:gap-y-16 lg:gap-y-20">
            <div className="xs:col-span-4 lg:col-span-8 xl:col-span-3 max-xl:flex max-xl:justify-center">
              <div>
                <Logo className="text-white max-xl:mx-auto" />
                <div className="flex items-center mt-4 xs:mt-6 xl:mt-16 gap-6">
                  <div className="label text-white/50">Volg ons</div>
                  <Socials />
                </div>
              </div>
            </div>
            <div className="xs:col-span-2 max-xs:text-center">
              <h2 className="label text-white/50">{footer?.contact?.title}</h2>
              <div className="mt-4 xs:mt-6 text-white text-sm font-bold">
                {info.addressRuleOne}
                <br />
                {info.addressRuleTwo}
                <br />
                {info.addressRuleThree}
                <br />
              </div>
              <div className="mt-4 xs:mt-6 flex flex-col gap-2 w-[200px] max-xs:mx-auto">
                <a
                  href={`tel:${info.phonenumber}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn--white--outline py-2 px-4 items-center text-sm"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-3">
                    <g clipPath="url(#clip0_1113_286)">
                      <path
                        d="M10.6501 12.1958C8.46644 11.2711 6.72994 9.5306 5.8104 7.34475L8.3604 4.79025L3.6654 0.0915L1.28715 2.469C0.874647 2.88386 0.548507 3.3764 0.327596 3.91812C0.106685 4.45984 -0.00461116 5.03999 0.000146326 5.625C0.000146326 11.061 6.93915 18 12.3751 18C12.9601 18.0051 13.5402 17.894 14.0819 17.673C14.6235 17.4521 15.1159 17.1258 15.5304 16.713L17.9086 14.3348L13.2099 9.636L10.6501 12.1958ZM14.4691 15.6525C14.1937 15.9255 13.8668 16.1411 13.5073 16.2866C13.1478 16.4321 12.7629 16.5046 12.3751 16.5C7.7004 16.5 1.50015 10.2998 1.50015 5.625C1.49573 5.23712 1.56837 4.85223 1.71385 4.49264C1.85933 4.13306 2.07476 3.80594 2.34765 3.53025L3.6654 2.2125L6.24315 4.79025L4.0419 6.9915L4.22565 7.452C4.76658 8.89898 5.61222 10.2128 6.70526 11.3044C7.7983 12.396 9.11321 13.24 10.5609 13.779L11.0154 13.9523L13.2099 11.757L15.7876 14.3348L14.4691 15.6525ZM10.5001 1.5V0C12.4886 0.0021837 14.395 0.793061 15.801 2.19911C17.2071 3.60516 17.998 5.51155 18.0001 7.5H16.5001C16.4984 5.90925 15.8656 4.38416 14.7408 3.25933C13.616 2.1345 12.0909 1.50179 10.5001 1.5ZM10.5001 4.5V3C11.6933 3.00119 12.8372 3.47568 13.6808 4.31934C14.5245 5.16299 14.999 6.30689 15.0001 7.5H13.5001C13.5001 6.70435 13.1841 5.94129 12.6215 5.37868C12.0589 4.81607 11.2958 4.5 10.5001 4.5Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                  {info.phonenumber}
                </a>
                <a href={info.whatsapp} target="_blank" rel="noreferrer" className="btn--white--outline py-2 px-4 items-center text-sm">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-3">
                    <path
                      d="M0 18L1.26353 13.5282C0.451766 12.1528 0.028237 10.587 0.028237 8.97884C0.028237 4.02743 4.05882 0 9.01412 0C13.9694 0 18 4.02743 18 8.97884C18 13.9302 13.9694 17.9577 9.01412 17.9577C7.46823 17.9577 5.95765 17.5627 4.61647 16.815L0 18ZM4.85647 15.1787L5.13176 15.348C6.29647 16.0603 7.63765 16.4342 9.01412 16.4342C13.1294 16.4342 16.4753 13.0909 16.4753 8.97884C16.4753 4.86677 13.1294 1.52351 9.01412 1.52351C4.89882 1.52351 1.55294 4.86677 1.55294 8.97884C1.55294 10.4107 1.96235 11.8002 2.73176 12.9992L2.91529 13.2884L2.18823 15.8628L4.85647 15.1787Z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.37604 4.32536L5.77053 4.2863C5.58085 4.27848 5.39846 4.34099 5.25255 4.47382C4.96074 4.74729 4.49384 5.27079 4.34794 5.95837C4.13637 6.98192 4.46466 8.23207 5.31821 9.48222C6.17177 10.7324 7.76944 12.7326 10.5927 13.5843C11.5046 13.8577 12.2196 13.6702 12.7667 13.2952C13.2045 12.9983 13.5036 12.5138 13.613 11.9747L13.7078 11.4903C13.737 11.334 13.6641 11.1855 13.5327 11.1152L11.5046 10.1151C11.3733 10.0526 11.2128 10.0917 11.1253 10.2167L10.3228 11.3262C10.2644 11.4121 10.1623 11.4434 10.0675 11.4043C9.52032 11.2012 7.6819 10.3729 6.67514 8.29458C6.63137 8.20081 6.63867 8.09143 6.70433 8.02111L7.47033 7.07569C7.55058 6.98192 7.56517 6.84128 7.5214 6.72408L6.64596 4.5207C6.60219 4.41131 6.49276 4.33318 6.37604 4.32536Z"
                      fill="currentColor"
                    />
                  </svg>
                  WhatsApp ons
                </a>
                <a
                  href={`mailto:${info.emailAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn--white--outline py-2 px-4 items-center text-sm"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-3">
                    <g clipPath="url(#clip0_1113_275)">
                      <path
                        d="M15.75 0.750244H2.25C1.65326 0.750244 1.08097 0.987297 0.65901 1.40925C0.237053 1.83121 0 2.40351 0 3.00024L0 17.2502H18V3.00024C18 2.40351 17.7629 1.83121 17.341 1.40925C16.919 0.987297 16.3467 0.750244 15.75 0.750244ZM2.25 2.25024H15.75C15.9489 2.25024 16.1397 2.32926 16.2803 2.46991C16.421 2.61057 16.5 2.80133 16.5 3.00024V3.50049L10.5915 9.40974C10.1688 9.83073 9.59656 10.0671 9 10.0671C8.40344 10.0671 7.83118 9.83073 7.4085 9.40974L1.5 3.50049V3.00024C1.5 2.80133 1.57902 2.61057 1.71967 2.46991C1.86032 2.32926 2.05109 2.25024 2.25 2.25024ZM1.5 15.7502V5.62524L6.348 10.4702C7.05197 11.1724 8.00569 11.5668 9 11.5668C9.99431 11.5668 10.948 11.1724 11.652 10.4702L16.5 5.62524V15.7502H1.5Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                  {info.emailAddress}
                </a>
              </div>
            </div>
            <nav className="xs:col-span-2 max-xs:text-center">
              <h2 className="label text-white/50">{footer?.menuOne?.title}</h2>
              <ul className="mt-4 xs:mt-6">
                {footer?.menuOne?.items?.map((item, index) => (
                  <li key={`${item.link.url}-${index}`} className="mt-2">
                    <LosseLink
                      className="text-white/75 text-sm font-bold hover:text-white hover:underline"
                      to={item?.link?.url}
                      target={item?.link?.target}
                    >
                      {losseContentParse(item?.link?.title)}
                    </LosseLink>
                  </li>
                ))}
              </ul>
            </nav>
            <nav className="xs:col-span-2 max-xs:text-center">
              <h2 className="label text-white/50">{footer?.menuTwo?.title}</h2>
              <ul className="mt-4 xs:mt-6">
                {footer?.menuTwo?.items?.map((item, index) => (
                  <li key={`${item.link.url}-${index}`} className="mt-2">
                    <LosseLink
                      className="text-white/75 text-sm font-bold hover:text-white hover:underline"
                      to={item?.link?.url}
                      target={item?.link?.target}
                    >
                      {losseContentParse(item?.link?.title)}
                    </LosseLink>
                  </li>
                ))}
              </ul>
            </nav>
            <nav className="xs:col-span-2 max-xs:text-center">
              <h2 className="label text-white/50">{footer?.menuThree?.title}</h2>
              <ul className="mt-4 xs:mt-6">
                {footer?.menuThree?.items?.map((item, index) => (
                  <li key={`${item.link.url}-${index}`} className="mt-2">
                    <LosseLink
                      className="text-white/75 text-sm font-bold hover:text-white hover:underline"
                      to={item?.link?.url}
                      target={item?.link?.target}
                    >
                      {losseContentParse(item?.link?.title)}
                    </LosseLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="mt-20 xs:flex items-center justify-end text-white/50 text-sm font-bold max-xs:text-center">
            <button
              type="button"
              onClick={() => {
                // @ts-ignore
                if (typeof Cookiebot !== 'undefined') Cookiebot.show()
              }}
              className="hover:text-white hover:underline max-xs:block"
            >
              Cookies
            </button>
            <span className="mx-2 max-xs:hidden">|</span>
            {footer.policies?.map((policy, index) => (
              <Fragment key={`${policy?.link?.url}-${index}`}>
                <span className="mx-2 max-xs:hidden">|</span>
                <LosseLink src={policy?.link} className="hover:text-white hover:underline max-xs:block" />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
